<template>
  <en-table-layout :tableData="tableData.data" :loading="loading">
    <template slot="toolbar">
      <el-form-item label="售后类型" class="col-auto">
        <el-select style="width: 100px" v-model="params.refuse_type" size="small" placeholder="请选择" clearable>
          <el-option label="全部" value="" />
          <el-option label="仅退款" value="RETURN_MONEY"></el-option>
          <el-option label="商家退款" value="SHOP_REFUND"></el-option>
          <el-option label="退款退货" value="RETURN_GOODS" />
          <el-option label="换货" value="EXCHANGE_GOODS" />
          <el-option label="维修" value="REPAIR_GOODS" />
          <el-option label="商家退款" value="SHOP_REFUND" />
          <el-option label="换新" value="RETURN_REPLACE_GOODS" />
          <el-option label="更换商品" value="REPLACE_GOODS" />
          <el-option label="售后补发" value="AFTER_REISSUE" />
        </el-select>
      </el-form-item>

      <el-form-item label="售后状态" class="col-auto">
        <el-select style="width: 120px" v-model="params.refund_status" size="small" placeholder="请选择" clearable>
          <el-option label="全部" value="" />
          <el-option label="申请中" value="APPLY" />
          <el-option label="待寄回" value="WAIT_SEND_BACK" />
          <el-option label="已寄回" value="SEND_BACK" />
          <el-option label="已完成" value="COMPLETED" />
          <el-option label="已拒绝" value="REFUSE" />
        </el-select>
      </el-form-item>
      <el-form-item label="来源应用" class="col-auto">
        <el-select style="width: 100px" v-model="params.mall_type" size="small" placeholder="请选择" clearable>
          <el-option label="全部" value="" />
          <el-option label="现金商城" :value="2" />
          <el-option label="套餐卡商城" :value="3" />
          <el-option label="AI智能套餐卡商城" :value="4" />
          <el-option label="双选商城" :value="5" />
          <el-option label="一件代发商城" :value="7" />
          <el-option label="小程序商城" :value="6" />
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间" class="col-auto">
        <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
          :editable="false" unlink-panels size="small" range-separator="-" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="timestamp"
          :picker-options="{ disabledDate(time) { return time.getTime() > Date.now() }, shortcuts: MixinPickerShortcuts }"></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-select style="width: 130px" v-model="params.keywordType" size="small" placeholder="请选择" clearable>
          <el-option label="根据售后单号" value="sn" />
          <el-option label="根据订单编号" value="trade_sn" />
          <el-option label="根据商品名称" value="goods_name" />
          <el-option label="根据下单账号" value="member_name" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input style="width: 150px" v-model="params.keywordValue" size="small" placeholder="根据关键字类型进行搜索"
          clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="advancedSearchEvent()" size="small" type="primary">搜索</el-button>
      </el-form-item>
    </template>

    <template slot="table-columns">
      <!--图片-->
      <el-table-column label="图片" fixed="left" min-width="88" max-width="250">
        <template slot-scope="scope">
          <img :src="scope.row.goods_image" style="width: 65px">
        </template>
      </el-table-column>
      <!--名称-->
      <el-table-column label="名称" min-width="100" max-width="250" fixed="left" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.goods_name }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        type="selection"
        width="55"
      >
      </el-table-column> -->
      <!--售后单号-->
      <el-table-column prop="sn" label="售后单号" width="130" fixed="left" />
      <!--订单编号-->
      <el-table-column prop="trade_sn" label="订单编号" width="130" fixed="left" :show-overflow-tooltip="true" />
      <!--订单来源-->
      <el-table-column label="订单来源" min-width="200" max-width="400" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.enterprise_name }}-
          {{ scope.row.shop_name }}
        </template>
      </el-table-column>
      <!--订单金额-->
      <!--      <el-table-column-->
      <!--        prop="refund_price"-->
      <!--        label="订单金额"-->
      <!--        width="100"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          {{ scope.row.refund_shop_price | unitPrice('￥') }}-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="shop_name" label="来源应用" width="180">
        <template slot-scope="{row}">
          <span v-if="row.mall_type == 2">现金商城</span>
          <span v-if="row.mall_type == 3">套餐卡商城</span>
          <span v-if="row.mall_type == 4">AI智能套餐卡商城</span>
          <span v-if="row.mall_type == 5">双选商城</span>
          <span v-if="row.mall_type == 7">一件代发商城</span>
          <span v-if="row.mall_type == 6">小程序商城</span>
        </template>
      </el-table-column>
      <!--下单账号-->
      <el-table-column prop="member_name" label="下单账号" width="150" class-name="font-family-tttgb">
        <template slot-scope="{row}">
          <p>{{ row.mobile || '' }}</p>
          <p>{{ row.login_account || '' }}</p>
        </template>
      </el-table-column>

      <!--提交时间-->
      <el-table-column prop="create_time" :formatter="MixinUnixToDate" label="提交时间" width="150"
        :show-overflow-tooltip="true" />
      <!--售后类型-->
      <el-table-column prop="refuse_type_text" label="售后类型" width="80" fixed="right" />
      <!--售后状态-->
      <el-table-column label="售后状态" width="100" fixed="right">
        <template slot-scope="scope">
          <span>{{ scope.row.refund_status_text }}</span><br>
          <el-tooltip v-if="scope.row.refund_status === 'AFTER_REISSUE'" style="margin-left: 4px;" class="item"
            effect="dark" content="待补发是指供应商已经售后审核通过，待重新补发商品的状态。待补发的售后状态归类到【已寄回】的售后状态筛选项中。" placement="top">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
          <span v-if="scope.row.refund_reason === '线下售后'" style="color: red;">
            (京东自动退回)
          </span>
          <span v-if="scope.row.refund_reason === '京东取消订单'" style="color: red;">
            (京东取消订单)
          </span>
        </template>
      </el-table-column>
      <!--操作-->
      <el-table-column label="操作" width="101" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleOperateOrder(scope.$index, scope.row)">查看订单</el-button>
        </template>
      </el-table-column>
    </template>
    <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
      :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :page-size="tableData.page_size"
      :layout="MixinTableLayout" :total="tableData.data_total" background>
    </el-pagination>
  </en-table-layout>
</template>

<script>
import * as API_order from "@/api/order";
import * as API_refund from "@/api/refund";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "powerList",
  components: { EnTableLayout },
  data () {
    return {
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        mall_type: "",//来源应用
        shop_member_type: 1,
        refund_status: "",
        refuse_type: "",
        keywordType: 'sn',
        keywordValue: ''
      },
      // 列表数据
      tableData: {},
      // 高级搜索数据
      advancedForm: {},
      orderType: 1,
    };
  },
  mounted () {
    this.GET_OrderList();
  },
  activated () {
    this.GET_OrderList();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_OrderList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList();
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        order_sn: data,
      };
      Object.keys(this.advancedForm).forEach((key) => delete this.params[key]);
      this.params.page_no = 1;
      this.GET_OrderList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      this.params = {
        ...this.params,
        ...this.advancedForm,
      };
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.advancedForm.order_time_range) {
        this.params.start_time = this.advancedForm.order_time_range[0] / 1000;
        this.params.end_time = this.advancedForm.order_time_range[1] / 1000;
      }

      //delete this.params.order_sn
      delete this.params.order_time_range;
      this.params.page_no = 1;
      this.GET_OrderList();
    },

    /** 查看、操作订单 */
    handleOperateOrder (index, row) {
      this.$router.push({
        name: "powerorderDetail",
        params: {
          sn: row.sn,
          callback: this.GET_OrderList,
        },
      });
    },
    /** 导出订单 */
    handleExportOrders () {
      const { advancedForm: params } = this;
      API_order.exportOrders(params).then((res) => {
        const json = {
          sheet_name: "订单列表",
          sheet_values: res.map((item) => ({
            订单编号: item.sn,
            下单时间: Foundation.unixToDate(item.create_time),
            订单总额: Foundation.formatPrice(item.shop_order_price),
            收货人: item.ship_name,
            订单状态: item.order_status_text,
            付款人: item.pay_member_name,
            付款状态: item.pay_status_text,
            发货状态: item.ship_status_text,
            支付方式: item.payment_type === "ONLINE" ? "在线支付" : "线下支付",
            订单来源: item.client_type,
          })),
        };
        this.MixinExportJosnToExcel(json, "订单列表");
      });
    },

    /** 获取订单列表 */
    GET_OrderList () {
      const params = JSON.parse(JSON.stringify(this.params))
      if (this.params.keywordValue) params[this.params.keywordType] = this.params.keywordValue
      if (params.refuse_type === 'REPLACE_GOODS') {
        params['mall_type'] = 4
      }
      API_refund.getRefundList(params)
        .then((res) => {
          this.loading = false;
          this.tableData = res;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-table__fixed::before,
/deep/.el-table__fixed-right::before {
  background-color: transparent;
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}
</style>
